var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "" },
      on: {
        closeModal: function () {
          _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f4 light-gray fw5" }, [
          _vm._v("Download Setting"),
        ]),
      ]),
      _c(
        "template",
        { slot: "body" },
        [
          _c("div", { staticClass: "w-100 flex white mw-900" }, [
            _c("div", { staticClass: "w-50 divider" }, [
              _c("div", { staticClass: "mb2 flex items-center" }, [
                _c("i", { staticClass: "f6 material-icons white mr1" }, [
                  _vm._v("hd"),
                ]),
                _vm._v("Resolution"),
              ]),
              _c(
                "div",
                { staticClass: "flex mb2" },
                _vm._l(_vm.resolutionList, function (res) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mr2 mb2 pa2 ba br2 f6 flex items-center pointer",
                      class:
                        _vm.resolution === res.value
                          ? "b--adori-red bg-adori-subtle-red white	"
                          : "b--grey gray",
                      on: {
                        click: function ($event) {
                          _vm.resolution = res.value
                        },
                      },
                    },
                    [
                      res.isPremium && !_vm.isProPlan
                        ? _c("span", [_vm._v("🔒 ")])
                        : _vm._e(),
                      _vm._v(" " + _vm._s(res.title) + "\n          "),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "mb2 flex items-center" }, [
                _c("i", { staticClass: "f6 material-icons white mr1" }, [
                  _vm._v("aspect_ratio"),
                ]),
                _vm._v("Orientation"),
              ]),
              _c(
                "div",
                { staticClass: "flex mb2" },
                _vm._l(_vm.orientationList, function (item) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mr2 mb2 pa2 ba br2 f6 flex items-center pointer",
                      class:
                        _vm.orientation === item.value
                          ? "b--adori-red bg-adori-subtle-red white	"
                          : "b--grey gray",
                      on: {
                        click: function ($event) {
                          _vm.orientation = item.value
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "mb2 flex items-center" }, [
                _c("i", { staticClass: "f6 material-icons white mr1" }, [
                  _vm._v("center_focus_weak"),
                ]),
                _vm._v(" Zoom effect\n        "),
              ]),
              _c(
                "div",
                { staticClass: "flex mb2" },
                _vm._l(_vm.zoomList, function (item) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mr2 mb2 pa2 ba br2 f6 flex items-center pointer",
                      class:
                        _vm.zoomEffect === item.value
                          ? "b--adori-red bg-adori-subtle-red white	"
                          : "b--grey gray ",
                      on: {
                        click: function ($event) {
                          _vm.zoomEffect = item.value
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "mb2 flex items-center" }, [
                _c("i", { staticClass: "f6 material-icons white mr1" }, [
                  _vm._v("fit_screen"),
                ]),
                _vm._v("Background Fit"),
              ]),
              _c(
                "div",
                { staticClass: "flex mb2" },
                _vm._l(_vm.backgroundFitList, function (item) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mr2 mb2 pa2 ba br2 f6 flex items-center pointer",
                      class:
                        _vm.backgroundFit === item.value
                          ? "b--adori-red bg-adori-subtle-red white	"
                          : "b--grey gray",
                      on: {
                        click: function ($event) {
                          _vm.backgroundFit = item.value
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "w-50" }, [
              _c("div", { staticClass: "mb2 flex items-center" }, [
                _c("i", { staticClass: "f6 material-icons white mr1" }, [
                  _vm._v("closed_caption"),
                ]),
                _vm._v("Subtitle"),
              ]),
              _c(
                "div",
                { staticClass: "flex mb3" },
                _vm._l(_vm.subtitleList, function (item) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mr2 mb2 pa2 ba br2 f6 flex items-center pointer",
                      class:
                        _vm.subtitle === item.value
                          ? "b--adori-red bg-adori-subtle-red white	"
                          : "b--grey gray",
                      on: {
                        click: function ($event) {
                          _vm.subtitle = item.value
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "mb2 flex items-center" }, [
                _c("i", { staticClass: "f6 material-icons white mr1" }, [
                  _vm._v("music_note"),
                ]),
                _vm._v(" Background Music\n        "),
              ]),
              _c(
                "div",
                { staticClass: "flex mb2" },
                _vm._l(_vm.musicList, function (item) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mr2 mb2 pa2 ba br2 f6 flex items-center pointer",
                      class:
                        _vm.music === item.value
                          ? "b--adori-red bg-adori-subtle-red white	"
                          : "b--grey gray ",
                      on: {
                        click: function ($event) {
                          return _vm.toggleBackgroundMusic(item.value)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "mb2 flex items-center" }, [
                _c("i", { staticClass: "f6 material-icons white mr1" }, [
                  _vm._v("blur_linear"),
                ]),
                _vm._v("Transition"),
              ]),
              _c(
                "div",
                { staticClass: "flex mb2" },
                _vm._l(_vm.transitionList, function (item) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mr2 mb2 pa2 ba br2 f6 flex items-center pointer",
                      class:
                        _vm.transition === item.value
                          ? "b--adori-red bg-adori-subtle-red white	"
                          : "b--grey gray ",
                      on: {
                        click: function ($event) {
                          _vm.transition = item.value
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("base-button-red", {
            staticClass: "w-100 mt3",
            attrs: {
              text: _vm.downloadBtnText,
              disabled: _vm.isRendering,
              loading: _vm.isRendering,
              onClick: _vm.handleRender,
            },
          }),
          _vm.showMusicModal
            ? _c("ModalMusic", {
                on: {
                  closeModal: function ($event) {
                    _vm.showMusicModal = false
                  },
                  selectMusic: _vm.handleSelectMusic,
                },
              })
            : _vm._e(),
          _vm.showPlans
            ? _c("ModalPlans", {
                attrs: { customModal: "" },
                on: {
                  closeModal: function ($event) {
                    _vm.showPlans = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }