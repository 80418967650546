



















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import ModalPlans from './ModalPlans.vue'
import ModalMusic from '@/components/Modals/ModalMusic.vue'
import { Getter } from 'vuex-class'
import ModalKenburns from './ModalKenburns.vue'
import { CURRENT_ENV } from '@/constants/env'

@Component({
  components: {
    ModalPlans,
    ModalMusic,
    ModalKenburns,
  },
})
export default class ModalVideoSettings extends Vue {
  @Prop() isRendering!: boolean
  @Prop() alignment!: any

  @Getter networkType!: any

  showPlans = false
  resolution = 720
  resolutionList = [
    { title: '480p', value: 480, isPremium: false },
    { title: '720p', value: 720, isPremium: false },
    { title: '1080p', value: 1080, isPremium: true },
    // { title: '1440p', value: 1440, isPremium: true },
    // { title: '2160p', value: 2160, isPremium: true },
  ]

  orientation = this.alignment
  orientationList = [
    { title: 'Landscape', value: 'LANDSCAPE' },
    { title: 'Square', value: 'SQUARE' },
    { title: 'Portrait', value: 'PORTRAIT' },
  ]

  subtitle = true
  subtitleList = [
    { title: 'Burn subtile', value: true },
    { title: 'No subtitle', value: false },
  ]

  backgroundFit = 'containblur'
  backgroundFitList = [
    { title: 'Cover', value: 'cover' },
    { title: 'Blur', value: 'containblur' },
  ]

  transition = 'random'
  transitionList = [
    { title: 'Random', value: 'random' },
    { title: 'None', value: null },
  ]
  zoomEffect: any = null
  zoomList = [
    { title: 'None', value: null },
    {
      title: 'Zoom In',
      value: CURRENT_ENV === 'PROD' ? '7e52a493-2d13-4851-902e-adcba09d70e2' : '3a9afabb-f9d7-4c55-b338-7571da01bb12',
    },
    {
      title: 'Zoom Out',
      value: CURRENT_ENV === 'PROD' ? 'f653b8b5-5116-46c6-8c4d-73847f1a97b6' : '89ae0d6e-3f58-4124-8fda-706ef1e073fa',
    },
  ]

  music = 'none'
  musicList = [
    { title: 'No Music', value: 'none' },
    { title: 'Select Music', value: 'music_id' },
  ]

  showMusicModal = false
  selectedMusic!: any

  showKenburns = false

  zoomEffectId!: any

  //   get musicData() {
  //     return null
  //   }

  //   get selectedMusic() {
  //     //@ts-ignore
  //     return !!this.musicData ? this.musicData.music.title : 'No Music'
  //   }

  get isFreeAccount() {
    return ['VIDEO_FREE'].includes(this.networkType)
  }

  get isBasicPlan() {
    return ['VIDEO_BASIC', 'ADORI_BASIC_MONTHLY', 'ADORI_BASIC_YEARLY'].includes(this.networkType)
  }
  get isProPlan() {
    return [
      'VIDEO_PRO',
      'ADORI_PREMIUM_MONTHLY',
      'ADORI_PREMIUM_YEARLY',
      'ADORI_BUSINESS_MONTHLY',
      'ADORI_BUSINESS_YEARLY',
    ].includes(this.networkType)
  }

  get downloadBtnText() {
    return this.isFreeAccount || (!this.isProPlan && this.resolution === 1080) ? '🔒 Download Video' : 'Download Video'
  }

  toggleZoomEffect(value: string) {
    if (value != 'none') this.showKenburns = true
    else {
      this.zoomEffect = 'none'
      this.zoomEffectId = null
    }
  }
  toggleBackgroundMusic(value: string) {
    if (value != 'none') this.showMusicModal = true
    else {
      this.music = 'none'
      this.selectedMusic = null
    }
  }

  handleSelectMusic(data: any) {
    this.musicList = [
      { title: 'No Music', value: 'none' },
      { title: data.music.title, value: data.music.id },
    ]
    this.music = data.music.id

    this.selectedMusic = {
      id: data.music.id,
      source: data.music.source,
      volume: data.volume,
    }
  }

  selectZoomEffect(id: string) {
    this.showKenburns = false
    this.zoomEffectId = id
    this.zoomEffect = 'zoom'
    console.log(this.zoomEffectId)
  }

  handleRender() {
    if (this.isFreeAccount || (!this.isProPlan && this.resolution === 1080)) {
      this.showPlans = true
      return
    }
    this.$emit('render', {
      resolution: this.resolution,
      orientation: this.orientation,
      subtitle: this.subtitle,
      transition: this.transition,
      backgroundFit: this.backgroundFit,
      music: this.selectedMusic,
      zoomEffectId: this.zoomEffect,
    })
  }

  mounted() {
    this.resolution = this.isProPlan ? 1080 : 720
  }
}
